import $ from 'jquery'
$(document).ready(function () {
  let image = '.hero__image img';
  let caption = '.hero__image-caption';
  let image_container = '.hero__image';

  if ($('.hero-home__image img').length) {
    let num = $('.hero-home__image').attr('data-images');
    let rand = Math.floor(Math.random() * num);

    image_container = '.hero-home__image'
    image = '.hero-home__image-inner--'+rand;
    caption = '.hero-home__image-caption--'+rand;
  }

  $(image).show()

  $(image).on('load', () => {
    $(image).addClass('shown')
    $(caption).length && $(caption).show()
    $(caption).length && $(caption).addClass('shown')
    $(image_container).addClass('shown')
  })

  setTimeout(function() {
    $(image).addClass('shown')
    $(caption).length && $(caption).show()
    $(caption).length && $(caption).addClass('shown')
    $(image_container).addClass('shown')
  }, 300)

})
