import $ from 'jquery'
$(document).ready(function(){
  if( $('.hero-campaign__image img').length) {
    $('.hero-campaign__image img').on("load", function() {
        $(this).closest('.hero-campaign__image').addClass('img-has-loaded')
    })
    setTimeout(function() {
        $('.hero-campaign__image').addClass('img-has-loaded')
    }, 50)
  }
})
